import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import clsx from "clsx"
import { useSpring, animated, config, useTransition } from "react-spring"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import useHover from "../../hooks/useHover"

const menuItems = ["Home", "Profile", "Order History", "Sign out"]

const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 40,
  (x - window.innerWidth / 2) / 40,
  1.1,
]
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    marginBottom: theme.spacing(8),
  },
  title: {
    position: "relative",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 800,
    lineHeight: 1.6,
    marginBottom: theme.spacing(5),
  },
  subtitle: {
    fontFamily: '"Poppins", sans-serif',
  },
  uppercase: {
    textTransform: "uppercase",
  },
  line: {
    position: "absolute",
    height: "6px",
    width: "100px",
    bottom: -20,
    borderRadius: "2px",
    backgroundColor: theme.palette.secondary.main,
  },
  lineCenter: {
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
  },
}))

const Heading = ({ title, subtitle, uppercase, variant }) => {
  const classes = useStyles()
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))
  return (
    <div className={classes.root} style={{ textAlign: variant }}>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        className={clsx(classes.title, uppercase && classes.uppercase)}
      >
        {title}
        <div
          className={clsx(
            classes.line,
            variant === "center" && classes.lineCenter
          )}
        />
      </Typography>
      <Typography
        variant="h6"
        color="textSecondary"
        className={clsx(classes.subtitle)}
      >
        {subtitle}
      </Typography>
    </div>
  )
}

Heading.defaultProps = {
  variant: "center",
  uppercase: true,
}

export default Heading
