import React, { useCallback } from "react"
import clsx from "clsx"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { scroller } from "react-scroll"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"

import { IoIosMail, IoIosPhonePortrait, IoLogoFacebook } from "react-icons/io"
import { FaFacebook } from "react-icons/fa"

import links from "../../locales/fr/links"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    color: "#fff",
    paddingTop: theme.spacing(8),
    paddingTop: theme.spacing(8),
    background: theme.palette.primary.main,
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(4),
  },
  container: {},
  links: {},
  logo: {
    display: "block",
    height: "auto",
    maxWidth: "300px",
  },
  subheader: {
    color: "#fff",
    fontSize: "1.5em",
    fontWeight: 800,
  },
  list: {
    marginTop: 10,
    marginBottom: 20,
  },
  item: {
    color: "#b6c9d2",
  },
  icon: {
    fontSize: "1.5rem",
  },
}))

const OFFSET = -100

const MenuItem = props => {
  const classes = useStyles()
  const { label, icon, ...other } = props
  return (
    <ListItem button dense {...other}>
      {icon && (
        <ListItemIcon className={clsx(classes.item, classes.icon)}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          variant: "subtitle2",
          className: classes.item,
        }}
      />
    </ListItem>
  )
}
const Footer = ({ variant }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "Logo_eureka_blanc.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const handleScrollToContact = useCallback(() => {
    scroller.scrollTo("contact", {
      smooth: true,
      offset: OFFSET,
    })
  })

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Img
              fluid={data.logoImage.childImageSharp.fluid}
              className={classes.logo}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              disablePadding
              className={classes.list}
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={classes.subheader}
                >
                  Nos Solutions
                </ListSubheader>
              }
            >
              {links.services.map(link => (
                <MenuItem
                  label={link.label}
                  key={link.name}
                  component={Link}
                  to={link.url}
                  title={link.label}
                />
              ))}
            </List>
          </Grid>
          <Grid item md={4} xs={12}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              disablePadding
              className={classes.list}
              subheader={
                <ListSubheader component="div" className={classes.subheader}>
                  Liens utiles
                </ListSubheader>
              }
            >
              {variant === "primary" ? (
                <MenuItem
                  label="Demande d'informations et inscription"
                  onClick={handleScrollToContact}
                />
              ) : (
                <MenuItem
                  label="Demande d'informations et inscription"
                  component={Link}
                  to={"/#contact"}
                  title="Demande d'informations et inscription"
                />
              )}

              {variant === "primary" ? (
                <MenuItem
                  label="Devenir professeur"
                  onClick={handleScrollToContact}
                />
              ) : (
                <MenuItem
                  label="Devenir professeur"
                  component={Link}
                  to={"/#contact"}
                  title="Devenir professeur"
                />
              )}
              <MenuItem
                label="Qui sommes-nous?"
                component={Link}
                to={"/qui-sommes-nous"}
                title="Qui sommes-nous?"
              />
            </List>

            <List
              component="nav"
              aria-label="main mailbox folders"
              disablePadding
              className={classes.list}
              subheader={
                <ListSubheader component="div" className={classes.subheader}>
                  Contact
                </ListSubheader>
              }
            >
              <MenuItem
                component="a"
                label="info@eurekaschool.be"
                icon={<IoIosMail />}
                href="mailto://info@eurekaschool.be"
                title="Envoyez-nous un message"
              />
              <MenuItem
                component="a"
                label="+32 (0)475 95 98 46"
                icon={<IoIosPhonePortrait />}
                href="tel://+32 (0)475 95 98 46"
                title="Appelez-nous"
              />
              <MenuItem
                component="a"
                label="facebook.com/eurekaschool.be"
                icon={<FaFacebook />}
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://www.facebook.com/eurekaschool.be/"
                title="Page Facebook"
              />
            </List>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Footer.defaultProps = {
  variant: "primary",
}

export default Footer
