import React, { useState, useCallback, useRef, useEffect } from "react"
import { Location } from "@reach/router"
import clsx from "clsx"
import chroma from "chroma-js"
import { Events } from "react-scroll"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import { useSpring, animated, config, useTrail, useChain } from "react-spring"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { MdSort, MdClose } from "react-icons/md"

import MenuItem from "./MenuItem"
import useScrollPosition from "../../hooks/useScrollPosition"

import links from "../../locales/fr/links"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    display: "block",
    width: "100%",
    zIndex: 5,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
      paddingBotom: theme.spacing(1),
    },
  },
  rootOpen: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      //alignItems: "flex-start",
    },
  },
  container: {},
  links: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      height: "100vh",
    },
  },
  logo: {
    display: "block",
    height: "auto",
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "100px",
    },
  },
  button: {
    color: "#fff",
  },
  trail: {
    display: "block",
    position: "relative",
    zIndex: 7,
  },
  backgroundMobile: {
    display: "block",
    height: "100vh",
    position: "absolute",
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    backgroundColor: theme.palette.primary.main,
  },
  phoneLink: {
    color: "#dae6e8",
    fontWeight: 800,
  },
}))

const DENSE_SCROLL_POSITON = -300

const Menu = ({ links, variant }) => {
  const theme = useTheme()
  const classes = useStyles()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDense, setIsDense] = useState(false)

  const isMobile = useMediaQuery("(max-width:600px)")

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  })

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false)
  })

  useEffect(() => {
    Events.scrollEvent.register("begin", function(to, element) {
      handleMenuClose()
    })
  }, [])
  const color1 = chroma(theme.palette.primary.main)
    .alpha(0.9)
    .css()
  const color2 = chroma(theme.palette.primary.main)
    .alpha(0)
    .css()

  const rootStyle = useSpring({
    maxHeight: isDense ? 100000 : 0,
    background: isDense ? color1 : color2,
  })
  const backgroundMobileRef = useRef()
  const backgroundMobileStyle = useSpring({
    opacity: isMenuOpen ? 1 : 0,
    ref: backgroundMobileRef,
  })

  const trailRef = useRef()
  const trail = useTrail(links.length, {
    opacity: (isMobile && isMenuOpen) || !isMobile ? 1 : 0,
    x: (isMobile && isMenuOpen) || !isMobile ? 0 : 20,
    height: (isMobile && isMenuOpen) || !isMobile ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
    ref: trailRef,
  })

  useChain(
    isMenuOpen
      ? [backgroundMobileRef, trailRef]
      : [trailRef, backgroundMobileRef],
    isMenuOpen ? [0, 0.2] : [0, 0.2]
  )
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "Logo_eureka_blanc.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logoImageMobile: file(
        relativePath: { eq: "Logo_eureka_negatif_PNG.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < DENSE_SCROLL_POSITON && !isDense) {
      setIsDense(true)
    } else if (currPos.y > DENSE_SCROLL_POSITON && isDense) {
      setIsDense(false)
    }
  })

  return (
    <Location>
      {({ location }) => (
        <animated.div style={rootStyle} className={clsx(classes.root)}>
          <animated.div
            style={backgroundMobileStyle}
            className={clsx(classes.backgroundMobile)}
          />
          <Container maxWidth="xl" className={classes.navbar}>
            <nav className={classes.navbar}>
              <Link to="/">
                <Img
                  fluid={
                    isMobile
                      ? data.logoImageMobile.childImageSharp.fluid
                      : data.logoImage.childImageSharp.fluid
                  }
                  className={classes.logo}
                />
              </Link>

              <div
                className={classes.links}
                style={{
                  pointerEvents: !isMobile || isMenuOpen ? "auto" : "none",
                }}
              >
                {trail.map(({ x, height, ...rest }, index) => (
                  <animated.div
                    key={links[index].name}
                    style={{
                      ...rest,
                      transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
                    }}
                    className={classes.trail}
                  >
                    <MenuItem
                      key={links[index].name}
                      link={links[index]}
                      location={location}
                      isMobile={isMobile}
                      onMenuClose={handleMenuClose}
                      isScrollLink={variant === "primary"}
                    />
                  </animated.div>
                ))}
              </div>
              <Typography
                variant="subtitle2"
                display="block"
                className={classes.phoneLink}
                component="a"
                href="tel://+32 (0)475 95 98 46"
              >
                +32 (0)475 95 98 46
              </Typography>
              {isMobile && (
                <IconButton
                  aria-label="open-menu"
                  className={classes.button}
                  size="large"
                  onClick={handleMenuToggle}
                >
                  {isMenuOpen ? (
                    <MdClose fontSize="inherit" />
                  ) : (
                    <MdSort fontSize="inherit" />
                  )}
                </IconButton>
              )}
            </nav>
          </Container>
        </animated.div>
      )}
    </Location>
  )
}

Menu.defaultProps = {
  variant: "primary",
  links: links.menu,
}

export default Menu
