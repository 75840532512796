import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { scroller } from "react-scroll"
import chroma from "chroma-js"
import Plx from "react-plx"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import Hero from "../components/Hero"
import Section from "../components/Section"
import Heading from "../components/Heading"
import VisibilityAnimation from "../components/VisibilityAnimation"
import SEO from "../components/SEO"
import MarkdownContent from "../components/MarkdownContent"

const useStyles = makeStyles(theme => ({
  informations: {
    display: "block",
    width: "100%",
    marginTop: theme.spacing(10),
    padding: theme.spacing(5),
    borderTop: "solid 6px " + theme.palette.primary.main,
    background: "#e6edf0",
    borderRadius: "10px",
  },
  image: {
    width: "100%",
    borderRadius: "20px",
  },
}))

const OFFSET = -100

const textData = [
  {
    start: "self",
    startOffset: 300,
    duration: 500,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
]

const IndexPage = ({ service, images, messages, format, InfosNodes }) => {
  const classes = useStyles()

  const handleClickFormLink = useCallback(e => {
    e.preventDefault()
    scroller.scrollTo("contact", {
      smooth: true,
      offset: OFFSET,
    })
  })

  useEffect(() => {
    Array.from(document.querySelectorAll("a[href='#contact']")).forEach(
      element => {
        element.addEventListener("click", handleClickFormLink)
      }
    )

    return function cleanup() {
      Array.from(document.getElementsByClassName("services-form-link")).forEach(
        element => {
          element.removeEventListener("click", handleClickFormLink)
        }
      )
    }
  })

  const ItemsBlocs = messages("services." + service + ".blocs").map(
    (bloc, i) => {
      return (
        <Section bg={i % 2 === 0 ? "#FFFFFF" : "#f2f6f7"} key={bloc.key}>
          <Grid container spacing={8}>
            {i % 2 === 0 && (
              <Grid item md={6} alignItems="center" container>
                <Img fluid={images[0]} className={classes.image} />
              </Grid>
            )}

            <Grid item md={6} alignItems="center" container>
              <div>
                <Heading title={bloc.title} variant="left" />

                {bloc.items.map(item => {
                  return (
                    <React.Fragment key={item.key}>
                      <Typography variant="h3">{item.subtitle}</Typography>
                      {format(item.content, { html: true })}
                    </React.Fragment>
                  )
                })}
              </div>
            </Grid>
            {i % 2 !== 0 && (
              <Grid item md={6} alignItems="center" container>
                <Img fluid={images[1]} className={classes.image} />
              </Grid>
            )}
          </Grid>
        </Section>
      )
    }
  )

  const InfosBlocs = InfosNodes.map(item => {
    return (
      <Grid item md={item.node.frontmatter.col} xs={12} key={item.node.id}>
        <Typography variant="h3" style={{ lineHeight: 1.5 }}>
          {item.node.frontmatter.title}
        </Typography>
        <MarkdownContent content={item.node.frontmatter.content} />
      </Grid>
    )
  })

  const title = messages("services." + service + ".title")
  return (
    <React.Fragment>
      <SEO title={title} />
      <Hero title={title} minHeight="40vh" variant="secondary"></Hero>
      {ItemsBlocs}
      <Section variant="secondary" id="service-informations">
        <Heading
          title={messages("services." + service + ".informations.title")}
        />
        <Grid container spacing={5}>
          {InfosBlocs}
        </Grid>
      </Section>
    </React.Fragment>
  )
}

IndexPage.propTypes = {
  children: PropTypes.node,
  InfosNodes: PropTypes.array,
}

IndexPage.defaultProps = {
  InfosNodes: [],
}

export default IndexPage
