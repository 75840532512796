import { red } from "@material-ui/core/colors"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#25353C",
    },
    secondary: {
      main: "#E8BE1F",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    htmlFontSize: 18,
    fontSize: 18,
    fontFamily: "'Poppins', sans-serif",
    body2: {
      lineHeight: 1.8,
    },
    h1: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 800,
      fontSize: "3.5rem",
      lineHeight: 1.4,
      letterSpacing: "0em",
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontSize: "1.3rem",
      lineHeight: 1.04,
      letterSpacing: "0em",
    },
  },
  overrides: {
    MuiListItem: {
      dense: {
        paddingTop: 1,
        paddingBottom: 1,
      },
    },
  },
})

export default responsiveFontSizes(theme)
