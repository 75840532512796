import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import clsx from "clsx"
import Img from "gatsby-image"
import shortid from "shortid"
import { useFormik } from "formik"
import VisibilitySensor from "react-visibility-sensor"
import { useSpring, animated, config, useTransition } from "react-spring"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { Slider } from "react-soft-slider"
import Typography from "@material-ui/core/Typography"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import { TiArrowRight } from "react-icons/ti"
import useHover from "../../hooks/useHover"

import Button from "../Button"

const menuItems = ["Home", "Profile", "Order History", "Sign out"]

const useStyles = makeStyles(theme => ({
  root: {},
  background: {
    background: theme.palette.secondary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    position: "absolute",
    top: "50%",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  container: {
    minHeight: 500,
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#fff",
    boxShadow: "10px 10px 48px 0px rgba(37,53,60,0.2)",
    borderRadius: "20px",
    padding: theme.spacing(4),
  },
  label: {
    textTransform: "uppercase",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  input: {
    display: "block",
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: "#F8F9FF",
    boxShadow: "none",
    border: "solid 2px " + theme.palette.primary.main,
    borderRadius: "6px",
    outline: "none",
  },
  textarea: {
    minHeight: "250px",
  },
}))

const slides = ["red", "blue", "yellow", "orange"]
const style = { width: 300, height: "100%", margin: "0 10px" }

const Contact = ({ children }) => {
  const classes = useStyles()
  const [isVisible, setIsVisible] = useState(false)

  const opacityStyle = useSpring({
    opacity: isVisible ? 1 : 0.3,
  })

  return (
    <VisibilitySensor
      onChange={setIsVisible}
      partialVisibility={true}
      intervalDelay={500}
      scrollDelay={2000}
      offset={-150}
    >
      <animated.div style={opacityStyle}>{children}</animated.div>
    </VisibilitySensor>
  )
}

Contact.defaultProps = {}

export default Contact
