import React from "react"
import clsx from "clsx"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "100vh",
    height: "0px",
  },
  rootSecondary: {
    minHeight: "50vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 2,
    maxWidth: "60%",
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "96%",
    },
  },
  overlay: {
    background: theme.palette.primary.main,
    position: "absolute !important",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: -2,
  },
  background: {
    position: "absolute !important",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0.2,
    overflow: "hidden",
  },
  logo: {
    display: "block",
    width: "100%",
  },
  waves: {
    position: "absolute !important",
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  title: {
    fontWeight: 800,
    marginBottom: theme.spacing(5),
    color: "#fff",
  },
  titleSecondary: {
    marginTop: theme.spacing(7),
  },
  subtitle: {
    fontFamily: '"Poppins", sans-serif',
    color: "#b6c9d2",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginBottom: theme.spacing(5),
    },
  },
}))

const Hero = ({ title, subtitle, children, variant }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <div
        className={clsx(
          classes.root,
          variant === "secondary" && classes.rootSecondary
        )}
      >
        <div className={classes.overlay} />
        <Img
          fluid={data.backgroundImage.childImageSharp.fluid}
          className={classes.background}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <div
          className={classes.content}
          style={{
            textTransform: variant === "primary" ? "none" : "uppercase",
          }}
        >
          <Typography
            variant="h1"
            className={clsx(
              classes.title,
              variant === "secondary" && classes.titleSecondary
            )}
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
          {children}
        </div>
        {variant === "primary" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 120 1440 100"
            className={classes.waves}
          >
            <path
              fill="#f2f6f7"
              fillOpacity="1"
              d="M0,160L48,160C96,160,192,160,288,170.7C384,181,480,203,576,192C672,181,768,139,864,128C960,117,1056,139,1152,149.3C1248,160,1344,160,1392,160L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        )}
      </div>
    </React.Fragment>
  )
}

Hero.defaultProps = {
  minHeight: "80vh",
  variant: "primary",
}

export default Hero
