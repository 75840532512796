export default {
  menu: [
    {
      name: "accueil",
      label: "Accueil",
      url: "/#acceuil",
      children: [],
    },
    /* {
      name: "notre-ecole",
      label: "Notre école",
      url: "/#notre-ecole",
      children: [],
    },*/
    {
      name: "nos-solutions",
      label: "Nos solutions",
      url: "/#nos-solutions",
      children: [
        {
          title: "Primaire",
          items: [
            {
              name: "cours-particuliers",
              label: "Cours particuliers",
              url: "/cours-particuliers",
            },

            {
              name: "ecole-des-devoirs",
              label: "Ecole des devoirs",
              url: "/ecole-des-devoirs",
            },
          ],
        },
        {
          title: "Secondaire",
          items: [
            {
              name: "cours-particuliers",
              label: "Cours particuliers",
              url: "/cours-particuliers",
            },
            {
              name: "coaching-scolaire",
              label: "Coaching scolaire",
              url: "/coaching-scolaire",
            },
            {
              name: "semaine-de-soutien-scolaire",
              label: "Semaine de soutien scolaire",
              url: "/semaine-de-soutien-scolaire",
            },
            {
              name: "orientation-scolaire",
              label: "Orientation scolaire",
              url: "/orientation-scolaire",
            },
            {
              name: "ecole-des-devoirs",
              label: "Ecole des devoirs",
              url: "/ecole-des-devoirs",
            },
          ],
        },
        {
          title: "Supérieur",
          items: [
            {
              name: "blocus-assiste",
              label: "Blocus assisté",
              url: "/blocus-assiste",
            },
            {
              name: "coaching-scolaire",
              label: "Coaching scolaire",
              url: "/coaching-scolaire",
            },
            {
              name: "cours-particuliers",
              label: "Cours particuliers",
              url: "/cours-particuliers",
            },
            {
              name: "orientation-scolaire",
              label: "Orientation scolaire",
              url: "/orientation-scolaire",
            },
          ],
        },
      ],
    },
    {
      name: "notre-adn",
      label: "Notre ADN",
      url: "/#notre-adn",
      children: [],
    },
    {
      name: "temoignages",
      label: "Témoignages",
      url: "/#temoignages",
      children: [],
    },
    {
      name: "contact",
      label: "Contact",
      url: "/#contact",
      children: [],
    },
  ],
  cards: {
    primaire: [
      {
        name: "cours-particuliers",
        label: "Cours particuliers",
        url: "/cours-particuliers",
      },

      {
        name: "ecole-des-devoirs",
        label: "Ecole des devoirs",
        url: "/ecole-des-devoirs",
      },
    ],
    secondaire: [
      {
        name: "semaine-de-soutien-scolaire",
        label: "Semaine de soutien scolaire",
        url: "/semaine-de-soutien-scolaire",
      },
      {
        name: "coaching-scolaire",
        label: "Coaching scolaire",
        url: "/coaching-scolaire",
      },
      {
        name: "cours-particuliers",
        label: "Cours particuliers",
        url: "/cours-particuliers",
      },
      {
        name: "orientation-scolaire",
        label: "Orientation scolaire",
        url: "/orientation-scolaire",
      },
      {
        name: "ecole-des-devoirs",
        label: "Ecole des devoirs",
        url: "/ecole-des-devoirs",
      },
    ],
    superieur: [
      {
        name: "blocus-assiste",
        label: "Blocus assisté",
        url: "/blocus-assiste",
      },
      {
        name: "coaching-scolaire",
        label: "Coaching scolaire",
        url: "/coaching-scolaire",
      },
      {
        name: "cours-particuliers",
        label: "Cours particuliers",
        url: "/cours-particuliers",
      },
      {
        name: "orientation-scolaire",
        label: "Orientation scolaire",
        url: "/orientation-scolaire",
      },
    ],
  },
  services: [
    {
      name: "coaching-scolaire",
      label: "Coaching scolaire",
      url: "/coaching-scolaire",
    },
    {
      name: "cours-particuliers",
      label: "Cours particuliers",
      url: "/cours-particuliers",
    },
    {
      name: "semaine-de-soutien-scolaire",
      label: "Semaine de soutien scolaire",
      url: "/semaine-de-soutien-scolaire",
    },
    {
      name: "ecole-des-devoirs",
      label: "Ecole des devoirs",
      url: "/ecole-des-devoirs",
    },
    {
      name: "orientation-scolaire",
      label: "Orientation scolaire",
      url: "/orientation-scolaire",
    },
    {
      name: "blocus-assiste",
      label: "Blocus assisté",
      url: "/blocus-assiste",
    },
  ],
}
