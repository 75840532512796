import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import clsx from "clsx"
import { Link as GatsbyLink } from "gatsby"
import { useSpring, animated, config, useTransition } from "react-spring"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import Grid from "@material-ui/core/Grid"

import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll"
import useHover from "../../hooks/useHover"

const HOME_PATHNAME = "/index2/"
const OFFSET = -100

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "inline-block",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  content: {
    position: "absolute",
    marginTop: theme.spacing(2),
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: "block",
    borderRadius: "5px",
    zIndex: 5,
    minWidth: "600px",
  },
  contentHovered: {},
  link: {
    color: "#fff",
    fontWeight: 600,
    textTransform: "uppercase",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    cursor: "pointer",
    transition: "color 0.5s ease",
    "&:hover": {
      color: "#b6c9d2",
    },
  },
  linkActive: {
    color: theme.palette.secondary.main,
  },
  zIndex: {
    zIndex: 5,
  },
  title: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 800,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "&::after": {
      content: "''",
      display: "block",
      height: "6px",
      width: "30px",
      borderRadius: "2px",
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const Menu = ({ link, isScrollLink, location, onMenuClose, isMobile }) => {
  const classes = useStyles()
  const [hoverRef, isHovered] = useHover()

  const props = useSpring({
    //maxHeight: isHovered ? 1000 : 0,
    opacity: isHovered ? 1 : 0,
    visibility: isHovered ? "visible" : "hidden",
  })

  const Items = link.children.map(section => {
    const ItemLinks = section.items.map(item => {
      return (
        <Grid item md={4} key={item.name}>
          <GatsbyLink to={item.url} activeClassName={classes.linkActive}>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                color: "inherit",
                variant: "subtitle2",
              }}
            />
          </GatsbyLink>
        </Grid>
      )
    })
    return (
      <React.Fragment>
        <Typography variant="h6" color="primary" className={classes.title}>
          {section.title}
        </Typography>
        <Grid container>{ItemLinks}</Grid>
      </React.Fragment>
    )
  })
  return (
    <div className={classes.root} ref={!isMobile ? hoverRef : undefined}>
      {isScrollLink ? (
        <Link
          activeClass={classes.linkActive}
          className={classes.link}
          to={link.name}
          spy={true}
          smooth={true}
          offset={OFFSET}
        >
          {link.label}
        </Link>
      ) : (
        <GatsbyLink
          to={link.url}
          className={clsx(
            classes.link,
            link.name === "nos-solutions" &&
              location.pathname !== "/" &&
              !location.pathname.includes("qui-sommes-nous") &&
              classes.linkActive,
            link.name === "notre-adn" &&
              location.pathname !== "/" &&
              location.pathname.includes("qui-sommes-nous") &&
              classes.linkActive
          )}
        >
          {link.label}
        </GatsbyLink>
      )}

      {link.children.length > 0 && (
        <animated.div style={props} className={clsx(classes.content)}>
          {Items}
        </animated.div>
      )}
    </div>
  )
}

Menu.defaultProps = {
  link: undefined,
  isScrollLink: false,
}

export default Menu
