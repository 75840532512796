import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import MaterialButton from "@material-ui/core/Button"

import useHover from "../../hooks/useHover"

const useStyles = makeStyles(theme => ({
  button: {
    padding: 12,
    minWidth: 250,
    color: "#fff",
    display: "inline-block",
    position: "relative",
    overflow: "hidden",
    fontWeight: 600,
    zIndex: 1,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "none",
    },
    "&::before": {
      position: "absolute",
      content: '""',
      transition: "all 0.35s ease-in-out 0s",
      top: 0,
      left: 0,
      width: "150%",
      height: "100%",
      zIndex: -1,
      transform: "rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0)",
      transformOrigin: "0% 100%",
      transition: "all 0.35s ease-in-out 0s",
    },
  },
  buttonHover: {
    background: "inherit",
    "&::before": {
      opacity: 1,
      transform: "rotate3d(0, 0, 1, 0deg)",
      transitionTimingFunction: "cubic-bezier(0.2, 1, 0.3, 1)",
    },
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&::before": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonSecondary: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&::before": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const Button = props => {
  const classes = useStyles()
  const [hoverRef, isHovered] = useHover()
  const { children, color, ...other } = props
  return (
    <MaterialButton
      ref={hoverRef}
      variant="contained"
      {...other}
      className={clsx(
        classes.button,
        isHovered && classes.buttonHover,
        color === "primary" && classes.buttonPrimary,
        color === "secondary" && classes.buttonSecondary
      )}
    >
      {children}
    </MaterialButton>
  )
}

Button.defaultProps = {
  color: "secondary",
}

export default Button
