import axios from "axios"

const BASE_URL = "https://us-central1-innobelge-websites.cloudfunctions.net"

export const BASE_PATH = "/emails"

export const sendEmail = (from, to, subject, domain, html, attachements) => {
  const data = new FormData()
  data.append("from", from)
  data.append("to", to)
  data.append("subject", subject)
  data.append("domain", domain)
  data.append("html", html)

  if (attachements && attachements.length > 0) {
    attachements.forEach((attachement, i) => {
      data.append(`file${i}`, attachement)
    })
  }
  return axios.post(BASE_URL + BASE_PATH, data)
}
