import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  section: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  background: {
    position: "relative",
    width: "100%",
    backgroundPosition: "bottom center",
    backgroundRepeat: "repeat-y",
    backgroundSize: "cover",
  },
}))

const Section = ({ children, id, bg, variant }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg_icons.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return variant === "secondary" ? (
    <BackgroundImage
      className={classes.root}
      backgroundColor="#f2f6f7"
      Tag="section"
      fluid={data.backgroundImage.childImageSharp.fluid}
    >
      <Container>
        <section className={classes.section} id={id}>
          {children}
        </section>
      </Container>
    </BackgroundImage>
  ) : (
    <div
      className={classes.root}
      style={{
        background: bg,
      }}
    >
      <Container>
        <section className={classes.section} id={id}>
          {children}
        </section>
      </Container>
    </div>
  )
}

export default Section
