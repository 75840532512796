import React, { useState } from "react"
import clsx from "clsx"
import { useFormik } from "formik"
import chroma from "chroma-js"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import swal from "sweetalert2"

import { makeStyles } from "@material-ui/core/styles"
import red from "@material-ui/core/colors/red"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"

import * as Yup from "yup"
import Button from "../Button"
import Heading from "../Heading"

import { sendEmail } from "../../config/email"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "100%",
    zIndex: 0,
    backgroundPosition: "bottom center",
    backgroundRepeat: "repeat-y",
    backgroundSize: "cover",
  },
  waves: {
    position: "absolute",
    top: -1,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    opacity: 0.7,
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 5,
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  form: {
    display: "block",
    maxWidth: 500,
    backgroundColor: "#fff",
    boxShadow: "10px 10px 48px 0px rgba(37,53,60,0.2)",
    borderRadius: "20px",
    padding: theme.spacing(4),
    position: "relative",
    zIndex: 6,
  },
  label: {
    textTransform: "uppercase",
    fontFamily: '"Poppins", sans-serif',
    fontSize: "0.8rem",
    fontWeight: 600,
    display: "block",
    marginBottom: theme.spacing(2),
  },
  input: {
    display: "block",
    width: "100%",
    fontSize: "1rem",
    padding: theme.spacing(2),
    backgroundColor: "#F8F9FF",
    fontFamily: '"Poppins", sans-serif',
    border: 0,
    //border: "solid 2px " + theme.palette.primary.main,
    boxShadow:
      "inset 0 2px 4px 0 " +
      chroma(theme.palette.primary.main)
        .alpha(0.1)
        .css(),
    borderRadius: "6px",
    outline: "none",
  },
  textarea: {
    minHeight: "250px",
    width: "100%",
    resize: "vertical",
  },
  inputError: {
    color: red[800],
    backgroundColor: red[100],
  },
  labelError: {
    color: red[800],
  },
  error: {
    color: red[800],
    fontWeight: 600,
    display: "block",
    fontSize: "0.7rem",
    marginTop: theme.spacing(2),
  },
  progress: {
    color: "#fff",
  },
}))

const ContactSchema = Yup.object().shape({
  nom: Yup.string().required("Ce champ est requis "),
  email: Yup.string()
    .email("Adresse email invalide")
    .required("Ce champ est requis "),
  telephone: Yup.string().required("Ce champ est requis "),
  message: Yup.string().required("Ce champ est requis "),
})

const Contact = ({ testimonials }) => {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg_contact.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const formik = useFormik({
    initialValues: {
      nom: "",
      email: "",
      telephone: "",
      message: "",
      newsletter: true,
    },
    validationSchema: ContactSchema,
    onSubmit: (response, { resetForm }) => {
      setIsLoading(true)

      const html = []
      Object.keys(response).forEach((key, index) => {
        console.log("response[index]", response[key])
        if (typeof response[key] === "string") {
          html.push(`<h3>${key}</h3><p>${response[key]}</p>`)
        } else if (typeof response[key] === "boolean") {
          html.push(`<h3>${key}</h3><p>${response[key] ? "oui" : "non"}</p>`)
        }
      })

      sendEmail(
        "noreply@eurekaschool.be",
        "thierrydemerode@gmail.com",
        "Demande d'informations",
        "innobelge.be",
        html.join(""),
        null
      )
        .then(() => {
          setIsLoading(false)
          resetForm()
          swal.fire(
            "Message Envoyé",
            "Nous reviendrons vers vous très prochainement",
            "success"
          )
        })
        .catch(error => {
          console.log(error)
          swal.fire("Message Non Envoyé", error.toString(), "error")
          setIsLoading(false)
        })
    },
  })
  console.log("values", formik.values)
  return (
    <BackgroundImage
      className={classes.root}
      Tag="section"
      fluid={data.backgroundImage.childImageSharp.fluid}
    >
      <div className={classes.container}>
        <Heading
          title="Contact"
          subtitle="Une question, une inscription ? N’hésitez pas, nous vous répondons dans les 24h"
        />
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <label
                htmlFor="nom"
                className={clsx(
                  classes.label,
                  formik.errors.nom && formik.touched.nom && classes.labelError
                )}
              >
                Nom
              </label>
              <input
                id="nom"
                name="nom"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nom}
                className={clsx(
                  classes.input,
                  formik.errors.nom && formik.touched.nom && classes.inputError
                )}
              />
              {formik.errors.nom && formik.touched.nom && (
                <span className={classes.error}>{formik.errors.nom}</span>
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <label
                htmlFor="email"
                className={clsx(
                  classes.label,
                  formik.errors.email &&
                    formik.touched.email &&
                    classes.labelError
                )}
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className={clsx(
                  classes.input,
                  formik.errors.email &&
                    formik.touched.email &&
                    classes.inputError
                )}
              />
              {formik.errors.email && formik.touched.email && (
                <span className={classes.error}>{formik.errors.email}</span>
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <label
                htmlFor="telephone"
                className={clsx(
                  classes.label,
                  formik.errors.telephone &&
                    formik.touched.telephone &&
                    classes.labelError
                )}
              >
                Téléphone
              </label>
              <input
                id="telephone"
                name="telephone"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.telephone}
                className={clsx(
                  classes.input,
                  formik.errors.telephone &&
                    formik.touched.telephone &&
                    classes.inputError
                )}
              />
              {formik.errors.telephone && formik.touched.telephone && (
                <span className={classes.error}>{formik.errors.telephone}</span>
              )}
            </Grid>

            <Grid item md={12} xs={12}>
              <label
                htmlFor="message"
                className={classes.label}
                className={clsx(
                  classes.label,
                  formik.errors.message &&
                    formik.touched.message &&
                    classes.labelError
                )}
              >
                Comment pouvons-nous vous aider ?
              </label>
              <textarea
                id="message"
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                className={clsx(
                  classes.input,
                  classes.textarea,
                  formik.errors.message &&
                    formik.touched.message &&
                    classes.inputError
                )}
              ></textarea>
              {formik.errors.message && formik.touched.message && (
                <span className={classes.error}>{formik.errors.message}</span>
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <label
                className={clsx(
                  "toggleButton",
                  formik.values.newsletter && "checked"
                )}
              >
                <input
                  type="checkbox"
                  name="newsletter"
                  id="newsletter"
                  onChange={e => {
                    formik.setFieldValue("newsletter", e.target.checked)
                  }}
                  checked={formik.values.newsletter}
                />
                <div>
                  <svg viewBox="0 0 44 44">
                    <path
                      d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758"
                      transform="translate(-2.000000, -2.000000)"
                    ></path>
                  </svg>
                </div>
                Je souhaite rester informé des offres d’Eureka
              </label>
            </Grid>
            <Grid item container justify="center">
              <Button type="submit" color="primary">
                {isLoading ? (
                  <CircularProgress size={24} className={classes.progress} />
                ) : (
                  "Envoyer le message"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={classes.overlay} />
      <div className={classes.waves}>
        <div
          style={{
            background: "#fff",
            width: "100%",
            display: "block",
            padding: "1em 0",
            minHeight: "400px",
          }}
        ></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{
            marginTop: -1,
            transform: "rotate(180deg)",
          }}
        >
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,160L48,160C96,160,192,160,288,170.7C384,181,480,203,576,192C672,181,768,139,864,128C960,117,1056,139,1152,149.3C1248,160,1344,160,1392,160L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </BackgroundImage>
  )
}

Contact.defaultProps = {}

export default Contact
