import React from "react"
import PropTypes from "prop-types"
import { Element, scroller } from "react-scroll"
import { Helmet } from "react-helmet"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"

import RootLayout from "../RootLayout"
import Menu from "../../Menu"
import Footer from "../../Footer"
import Contact from "../../Contact"
import Section from "../../Section"
import theme from "../../../theme"

const SecondaryLayout = ({ children }) => {
  return (
    <RootLayout>
      <Menu variant="secondary" />
      {children}

      <Element name="contact" id="contact">
        <Contact />
      </Element>

      <Footer variant="secondary" />
    </RootLayout>
  )
}

SecondaryLayout.propTypes = {
  children: PropTypes.node,
}

export default SecondaryLayout
