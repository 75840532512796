import shortid from "shortid"

export default {
  /*******************************
   *
   *  BLOCUS ASSISTE
   *
   *******************************/
  "blocus-assiste": {
    title: "Blocus Assisté",
    blocs: [
      {
        title: "À propos du blocus assisté Eureka",
        key: shortid.generate(),
        items: [
          {
            key: shortid.generate(),
            subtitle: "Objectifs",
            content: `  
            <ul>
              <li>
                Accompagner chaque étudiant dans la préparation de ses examens
                en mettant l’accent sur la <strong>compréhension</strong> et 
                <strong>l’ancrage des connaissances</strong>.
              </li>
              <li>
                Rendre l’étudiant <strong>autonome</strong> dans son
                apprentissage et développer sa confiance en lui.
              </li>
              <li>
                Chaque étudiant est acteur de son blocus assisté tout en
                bénéficiant du soutien scolaire et moral du professeur.
              </li>
            </ul>`,
          },
          {
            key: shortid.generate(),
            subtitle: "Pour Qui ?",
            content: `  
            <p>
              Tout étudiant en <strong>haute école</strong> ou à
              <strong>l’université</strong> qui désire préparer ses examens en bénéficiant d’un cadre et d’un accompagnement propice à l’étude.
            </p>`,
          },
          {
            key: shortid.generate(),
            subtitle: "Cadre au choix",
            content: `  
            <ul>
              <li>
                Blocus non résidentiel permettant aux étudiants de rentrer chez
                eux le soir.
              </li>
              <li>
                Blocus résidentiel dans un cadre d’exception propice à
                l’apprentissage : Loin du tumulte de la ville, Eureka organise
                des blocus assistés à la campagne (1h30 de Bruxelles).
              </li>
            </ul>`,
          },
        ],
      },
      {
        key: shortid.generate(),
        title: "Méthode pédagogique",
        items: [
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>
              Au début du blocus assisté, un <strong>planning d’étude</strong>
              est effectué avec l’étudiant en fonction des besoins de chaque
              matière.
            </p>
            <p>
              Il travaille de manière autonome. Le professeur débloque à tout
              moment l’élève dans son étude. Il répond aux questions, donne des
              <strong>explications individuelles</strong>, identifie ensemble
              les erreurs de raisonnement et blocages pour ancrer
              l’apprentissage.
            </p>
            <p>
              Énergie de groupe : L’étudiant n’est pas seul, il progresse au
              sein d’un groupe. <strong>L’esprit d’équipe</strong> donne de
              l’énergie et crée une ambiance d’étude motivante.
            </p>
            <p>
              Minimum <strong>9 heures</strong> par jour d’étude encadrée par un
              professeur expert dans la matière.
            </p>
            <p>
              Petit groupe de <strong>maximum 9</strong> étudiants par
              professeur.
            </p>
            <p>
              <strong>Structure et rythme</strong> : horaire fixe chaque jour de
              5 blocs d’études alternés avec des moments de pause et de détente.
              Règles strictes pendant l’étude (ex : pas de wifi ni téléphone
              pendant la journée).
            </p>`,
          },
        ],
      },
    ],
    informations: {
      title: "Informations pratiques",
      items: [
        {
          key: shortid.generate(),
          col: 6,
          subtitle: "Date de blocus assisté",
          content: `
          

          <p> <strong>Pâques 2020</strong></p>
          <ul>
            <li>Semaine 1 : lundi 6 avril – vendredi 10 avril</li>
            <li>Semaine 2 : lundi 13 avril – vendredi 17 avril</li>
          </ul>
          <p> <strong>Été 2020</strong></p>
          <ul>
            <li>Semaine 1 : lundi 13 juillet – vendredi 17 juillet</li>
            <li>Semaine 2 : lundi 20 juillet – vendredi 24 juillet</li>
            <li>Semaine 3 : lundi 27 juillet – vendredi 31 juillet</li>
            <li>Semaine 4 : lundi 3 août – vendredi 7 août</li>
            <li>Semaine 5 : lundi 10 août – vendredi 14 août</li>
            <li>Semaine 6 : lundi 17 août – vendredi 21 août</li>
          </ul>
          <p> <strong>Noël 2020</strong></p>`,
        },

        {
          key: shortid.generate(),
          col: 6,
          subtitle: "Lieu",
          content: `
          <p>
            Non résidentiel à Bruxelles : Boulevard Louis Schmidt 75, 1040
            Etterbeek
          </p>
          <p>
            Résidentiel (1h30 de Bruxelles) : Le Château 08430 Guignicourt-sur
            Vence, France. Le château offre un parc de 15 hectares, accès
            direct à la forêt, billard, salon cosy avec un feu de cheminée,
            tennis, piscine, chambre individuelle, …
          </p>
          <h3 style="font-weight: 600; color: rgba(0, 0, 0, 0.87);">Demande d’information et tarif</h3>
          <p>
          Contactez-nous au <a href="tel://+32475959846" title="Contactez-nous"><strong>+32 (0) 475 95 98 46</strong></a> ou via le
          <a href="#contact" class="services-form-link" title="Envoyez-nous un message"><strong>formulaire</strong></a>
        </p>`,
        },
      ],
    },
  },
  /*******************************
   *
   *  COACHING SCOLAIRE
   *
   *******************************/
  "coaching-scolaire": {
    title: "Coaching Scolaire",
    blocs: [
      {
        title: "À propos du coaching scolaire Eureka",
        key: shortid.generate(),
        items: [
          {
            key: shortid.generate(),
            subtitle: "Objectifs",
            content: `  
            <ul>
              <li>
                <strong>Accompagner l’étudiant</strong> dans l’appropriation de
                la matière au fur et à mesure du semestre en développant
                ensemble deux facteurs de succès complémentaires :
                <ul>
                  <li>
                    L’ <strong>ancrage</strong> continu des connaissances et des
                    acquis
                  </li>
                  <li>
                    L’<strong>apprentissage</strong> et la mise en place d’une
                    <strong>méthode de travail</strong>
                    régulière
                  </li>
                </ul>
              </li>
              <li>
                Rendre l’étudiant autonome dans son apprentissage et développer
                sa <strong>confiance en lui</strong>.
              </li>
              <li>
                L’étudiant devient acteur de son apprentissage au travers de
                petits objectifs qu’il se fixe d’une semaine à l’autre tout en
                bénéficiant du <strong>soutien scolaire et moral</strong> du
                coach.
              </li>
            </ul>`,
          },
          {
            key: shortid.generate(),
            subtitle: "Pour Qui ?",
            content: `  
            <p>
              Tout étudiant <strong>du supérieur</strong> (universitaire / haute
              école) ou <strong>du secondaire</strong> désirant mettre en place,
              avec le soutien d’un coach, un rythme de travail régulier tout au
              long de l’année.
            </p>`,
          },
        ],
      },
      {
        key: shortid.generate(),
        title: "Méthode pédagogique",
        items: [
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>
              <strong>Accompagnement individuel</strong> de l’étudiant d’une
              semaine à l’autre.
            </p>
            <p>
              Première rencontre : <strong>définir ensemble</strong> la demande
              (comprendre la situation de l’étudiant, ses attentes, ses
              besoins), les objectifs et le cadre de travail du coaching
              scolaire.
            </p>
            <p>
              Chaque rencontre démarre par un suivi des actions définies lors de
              la semaine passée et le passage en revue de chaque cours afin
              d’identifier les points à débloquer.
            </p>
            <p>
              Suivant la situation, l’étudiant et le coach définissent un plan
              d’action à court et moyen terme :
            </p>
            <ul>
              <li>
                <strong>Cours particuliers</strong> : répondre aux questions,
                expliquer le cours, identifier les erreurs de raisonnement,
                réalisation d’exercices, …
              </li>
              <li>
                <strong>Méthode de travail</strong> : définir la stratégie
                d’étude et les bonnes pratiques, apprendre à se poser les bonnes
                questions, créer un planning d’étude, …
              </li>
            </ul>

            <p>
              A la fin de la séance, le coach et l’étudiant fixent les objectifs
              de la semaine à venir et <strong>planifie la semaine</strong>
              d’étude.
            </p>
            <p>
              Mise en place d’une <strong>dynamique positive</strong> : focus
              sur les acquis et succès et non les faiblesses.
            </p>
            <p>
              <strong>Structure et rythme</strong> : <strong>horaire fixe</strong> chaque
              semaine.
            </p>`,
          },
        ],
      },
    ],
    informations: {
      title: "Informations pratiques",
      items: [
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Formules",
          content: `
          <p>
            <strong>Coaching scolaire standard</strong> : 2h/semaine pendant
            minimum 2 mois (8 séances).
          </p>
          <p>
            <strong>Coaching scolaire intensif</strong> : 2 x 2h/semaine
            pendant minimum 2 mois (16 séances).
          </p>
          <p>
            Horaire fixe chaque semaine. Engagement sur deux mois,
            renouvelable par mois (4 séances).
          </p>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Lieu",
          content: `
          <p>
            72 rue de l’ermitage, <strong>1050 Ixelles</strong>
          </p>
          <p>
            75 boulevard Louis Schmidt, <strong>1040 Etterbeek</strong>
          </p>
          <p>Soutien scolaire à domicile (+ frais de déplacement)</p>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Demande d’information et tarif",
          content: `
          <p>
            Contactez-nous au <a href="tel://+32475959846" title="Contactez-nous"><strong>+32 (0) 475 95 98 46</strong></a> ou via le
            <a href="#contact" class="services-form-link" title="Envoyez-nous un message"><strong>formulaire</strong></a>
          </p>`,
        },
      ],
    },
  },
  /*******************************
   *
   *  COURS PARTICULIERS
   *
   *******************************/
  "cours-particuliers": {
    title: "Cours Particuliers",
    blocs: [
      {
        title: "À propos des cours particuliers Eureka",
        key: shortid.generate(),
        items: [
          {
            key: shortid.generate(),
            subtitle: "Objectifs",
            content: `  
            <ul>
              <li>
                Travailler de manière <strong>ciblée et proactive</strong> une
                matière ou une notion spécifique afin d’en faciliter
                l’apprentissage et mettre toutes les chances de son côté pour
                réussir.
              </li>
              <li>
                Si besoin, <strong>remettre rapidement l’étudiant à flot</strong>
                par rapport à une difficulté spécifique dans une matière.
              </li>
              <li>
                Développer ou redonner à l’étudiant
                <strong>confiance en ses capacités</strong>.
              </li>
            </ul>`,
          },
          {
            key: shortid.generate(),
            col: 6,
            subtitle: "Pour Qui ?",
            content: `  
            <p>
              Tout étudiant (université, haute école, secondaire, primaire) qui
              a un besoin spécifique dans l’apprentissage d’une matière.
            </p>`,
          },
        ],
      },
      {
        key: shortid.generate(),
        title: "Méthode pédagogique",
        items: [
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>
              <strong>Accompagnement personnalisé</strong> de l’étudiant de
              manière ponctuelle ou récurrente.
            </p>
            <p>
              Identifier ensemble les lacunes ou les besoins de soutien scolaire
              spécifiques.
            </p>
            <p>
              Distinguer les conséquences des causes afin de cibler correctement
              la difficulté rencontrée par l’étudiant (ex : base non maîtrisée,
              raisonnement erroné, …)
            </p>
            <p>
              <strong>Agir concrètement</strong> en fonction de la situation :
              répondre aux questions, expliquer le cours, identifier les erreurs
              de raisonnement, réaliser des exercices, faire du drill,
              travailler la capacité à restituer le cours…
            </p>
            <p>Chaque étudiant est différent, les solutions le sont aussi.</p>
            <p>
              Mise en place d’une <strong>dynamique positive</strong> : focus
              sur les acquis et succès et non les faiblesses.
            </p>
            <p>Cours individuel, en binôme ou en groupe selon les besoins.</p>`,
          },
        ],
      },
    ],
    informations: {
      title: "Informations pratiques",
      items: [
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Formules",
          content: `
          <p>Cours particuliers - ponctuels : bloc de 2h </p>
          <p>Cours particuliers - suivi personnalisé : </p>
          <ul>
            <li>Forfait : 10h + 1h gratuite</li>
            <li>Forfait : 20h + 3h gratuite</li>
          </ul>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Lieu",
          content: `
          <p>
            72 rue de l’ermitage, <strong>1050 Ixelles</strong>
          </p>
          <p>
            75 boulevard Louis Schmidt, <strong>1040 Etterbeek</strong>
          </p>
          <p>Soutien scolaire à domicile (+ frais de déplacement)</p>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Demande d’information et tarif",
          content: `
          <p>
            Les prix varient en fonction de la formule et du nombre d’étudiants.
          </p>
          <p>
            Contactez-nous au <a href="tel://+32475959846" title="Contactez-nous"><strong>+32 (0) 475 95 98 46</strong></a> ou via le
            <a href="#contact" class="services-form-link" title="Envoyez-nous un message"><strong>formulaire</strong></a>
          </p>`,
        },
      ],
    },
  },
  /*******************************
   *
   *  SEMAINE DE SOUTIEN
   *
   *******************************/
  "semaine-de-soutien-scolaire": {
    title: "Semaine de soutien scolaire",
    blocs: [
      {
        title: "À propos de la semaine de soutien scolaire Eureka",
        key: shortid.generate(),
        items: [
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>
              La semaine de soutien scolaire est une <strong>semaine complète et intensive</strong> d’accompagnement scolaire pendant les vacances.
             
        
            </p>`,
          },
          {
            key: shortid.generate(),
            subtitle: "Objectifs",
            content: `  
            <ul>
              <li>
                Travailler de manière intensive pendant une semaine pour
                faciliter l’apprentissage des matières et mettre toutes les
                chances de son côté pour réussir.
              </li>
              <li>
                <strong>Apprendre à comprendre</strong> la matière en donnant
                du sens pour ancrer les acquis et prendre du plaisir dans
                l’étude.
              </li>
              <li>
                Découvrir les <strong>bonnes habitudes de travail</strong> pour
                rendre l’élève autonome dans son apprentissage et développer
                sa confiance en lui.
              </li>
              <li>
                <strong>Rendre l’élève acteur de sa scolarité</strong> tout en
                bénéficiant du soutien scolaire et moral du professeur.
              </li>
              <li>
                Fournir à l’élève un rythme d’étude adapté à ses besoins.
              </li>
            </ul>`,
          },
          {
            key: shortid.generate(),
            subtitle: "Pour Qui ?",
            content: `  
            <p>
            Tout élève de l’<strong>enseignement secondaire</strong> qui désire apprendre une méthode de travail efficace, se remettre à flot dans une matière, préparer un travail ou encore préparer ses examens en bénéficiant d’un cadre et d’un accompagnement propice à l’étude. 
            </p>`,
          },
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>
            Stage d’étude non résidentiel.
            </p>`,
          },
        ],
      },
      {
        key: shortid.generate(),
        title: "Méthode pédagogique",
        items: [
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>
              Au début de la semaine de soutien scolaire, les différents aspects
              de l’apprentissage d’une matière sont passés en revue : organiser
              son temps de travail, plan du cours, notions essentielles à
              acquérir, ...
            </p>
            <p>
              L’élève apprend à se <strong>fixer des objectifs</strong> réalistes
              par rapport à l’étude de sa matière.
            </p>
            <p>
              Il <strong>travaille de manière autonome</strong>. Le professeur
              le débloque à tout moment dans son étude. Il répond aux questions,
              donne des explications individuelles, identifie ensemble les
              erreurs de raisonnement et blocages pour ancrer l’apprentissage.
            </p>
            <p>
              <strong>Energie de groupe</strong> : L’élève n’est pas seul, il
              progresse au sein d’un groupe. L’esprit d’équipe donne de
              l’énergie et crée une ambiance d’étude motivante.
            </p>
            <p>
              <strong>6 heures d’étude encadrée</strong> par jour par un
              professeur expert dans la matière.
            </p>
            <p>
              Petit groupe de <strong>maximum 7 élèves</strong> par professeur
              pour favoriser l’entraide et l’échange de connaissance et garantir
              une attention spécifique à chacun.
            </p>
            <p>
              <strong>Structure et rythme</strong> : horaire fixe chaque jour de 4 blocs d’études
              encadrée de 1h30, alternés avec des moments de pause et de
              détente. Règles strictes pendant l’étude (ex : pas de wifi ni
              téléphone pendant la journée).
            </p>`,
          },
        ],
      },
    ],
    informations: {
      title: "Informations pratiques",
      items: [
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Dates des semaines de soutien scolaire",
          content: `
         <p> <strong>Carnaval 2020</strong></p>
          <ul>
            <li>Lundi 24 au vendredi 28 Février</li>
          </ul>
          <strong>Pâques 2020 </strong>
          <ul>
            <li><strong>Semaine 1 : </strong> lundi 6 – vendredi 10 avril</li>
            <li><strong>Semaine 2 : </strong> lundi 13 – vendredi 17 avril</li>
          </ul>
          <strong>Eté 2020</strong>
          <ul>
            <li><strong>Semaine 1 : </strong> lundi 3 – vendredi 7 août</li>
            <li><strong>Semaine 2 : </strong> lundi 10 – vendredi 14 août</li>
            <li><strong>Semaine 3 : </strong> lundi 17 – vendredi 21 août</li>
          </ul>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Lieu",
          content: `
          <p>
            72 rue de l’ermitage, <strong>1050 Ixelles</strong>
          </p>
          <p>
            75 boulevard Louis Schmidt, <strong>1040 Etterbeek</strong>
          </p>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Demande d’information et tarif",
          content: `
          <p>
            Contactez-nous au <a href="tel://+32475959846" title="Contactez-nous"><strong>+32 (0) 475 95 98 46</strong></a> ou via le
            <a href="#contact" class="services-form-link" title="Envoyez-nous un message"><strong>formulaire</strong></a>
          </p>`,
        },
      ],
    },
  },
  /*******************************
   *
   *  ECOLDE DES DEVOIRS
   *
   *******************************/
  "ecole-des-devoirs": {
    title: "École des devoirs",
    blocs: [
      {
        title: "À propos de l’école des devoirs Eureka",
        key: shortid.generate(),
        items: [
          {
            key: shortid.generate(),
            subtitle: "Objectifs",
            content: `  
            <ul>
              <li>
                <strong>Travailler de manière régulière</strong> les devoirs et
                la préparation des interros et examens pour mettre toutes les
                chances de son côté pour réussir.
              </li>
              <li>
                <strong>Être à jour dans son travail</strong> et anticiper les
                périodes plus chargées.
              </li>
              <li>
                Si besoin, remettre rapidement l’étudiant à flot par rapport à
                une difficulté spécifique dans une matière.
              </li>
              <li>
                Rentrer chaque jour à la maison avec la satisfaction des devoirs
                préparés et la certitude d’être prêt pour ses interrogations.
              </li>
            </ul>`,
          },
          {
            key: shortid.generate(),
            subtitle: "Pour Qui ?",
            content: `  
            <p>
              Tout élève <strong>du secondaire</strong> ou
              <strong>du primaire</strong> qui désire être accompagné par un
              professeur dans son travail journalier.
            </p>`,
          },
        ],
      },
      {
        key: shortid.generate(),
        title: "Méthode pédagogique",
        items: [
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>
              <strong>Etude dirigée journalière</strong> offrant un
              accompagnement personnalisé et continu à l’élève durant toute
              l’année scolaire.
            </p>
            <p>
              Chaque jour, l’EDD démarre par une lecture du journal de classe et
              l’identification des matières à étudier et travaux à réaliser.
            </p>
            <p>
              L’élève travaille de manière autonome. Le professeur le débloque
              dès que nécessaire. En fonction du besoin, il répond aux
              questions, explique le cours, identifie les erreurs de
              raisonnement, corrige les exercices, interroge l’élève, …
            </p>
            <p>
              <strong>Le professeur vérifie le travail effectué</strong> et
              l’acquisition des compétences.
            </p>
            <p>
              <strong>Structure et rythme</strong> : horaire fixe chaque jour
              durant la semaine.
            </p>`,
          },
        ],
      },
    ],
    informations: {
      title: "Informations pratiques",
      items: [
        {
          key: shortid.generate(),
          col: 3,
          subtitle: "Formules",
          content: `
          <p>Etude dirigée 5 fois / semaine (hors congé scolaire)</p>
          <p><strong>Forfait pour un mois</strong></p>
          <span><strong>Forfait pour un trimestre </strong>:</span>
          <ul>
            <li>Septembre à Décembre </li>
            <li>Janvier à Pâques</li>
            <li>Pâques à Juin</li>
          </ul>
          <p><strong>Forfait annuel</strong></p>`,
        },
        {
          key: shortid.generate(),
          col: 3,
          subtitle: "Horaires",
          content: `
          <p>En fonction de son horaire et du planning définit avec les parents, arrivée et départ flexible entre :</p>
          <p>
            <strong>16h30 – 19h00</strong> : lundi mardi jeudi et vendredi
          </p>
          <p>
            <strong>14h00 – 16h30</strong>, le mercredi
          </p>
          `,
        },
        {
          key: shortid.generate(),
          col: 3,
          subtitle: "Lieu",
          content: `
          <p>
            Avenue Franklin Roosvelt. 21, 1050 Bruxelles
          </p>`,
        },
        {
          key: shortid.generate(),
          col: 3,
          subtitle: "Demande d’information et tarif",
          content: `
          <p>
            Les prix varient en fonction de la formule.
          </p>
          <p>
            Contactez-nous au <a href="tel://+32475959846" title="Contactez-nous"><strong>+32 (0) 475 95 98 46</strong></a> ou via le
            <a href="#contact" class="services-form-link" title="Envoyez-nous un message"><strong>formulaire</strong></a>
          </p>`,
        },
      ],
    },
  },
  /*******************************
   *
   *  ORIENTATION SCOLAIRE
   *
   *******************************/
  "orientation-scolaire": {
    title: "Orientation Scolaire",
    blocs: [
      {
        title: "À propos de l’orientation scolaire Eureka",
        key: shortid.generate(),
        items: [
          {
            key: shortid.generate(),
            subtitle: "Objectifs",
            content: `  
            <ul>
              <li>
                Trouver ensemble les études, métiers et environnements de
                travail qui <strong>correspondent à sa personnalité</strong>, son projet de vie,
                ses talents.
              </li>
              <li>
                Aider l’étudiant à <strong>se poser les bonnes questions</strong> autour de son
                orientation scolaire et à définir un parcours professionnel.
              </li>
              <li>
                Développer une <strong>motivation</strong> et une implication dans ses études
                grâce à un choix qui fait sens.
              </li>
              <li>Développer <strong>sa connaissance et son estime de soi</strong>.</li>
              <li>
                Le jeune est acteur de son parcours d’orientation. L’étudiant
                progresse dans sa réflexion, pose un choix et agit pour
                concrétiser son projet. Le coach joue le rôle de miroir lui
                permettant de faire des prises de conscience clefs dans sa
                réflexion.
              </li>
            </ul>`,
          },
          {
            key: shortid.generate(),
            subtitle: "Pour Qui ?",
            content: `  
            <p>
              Tout jeune entre 15 et 28 ans qui
              <strong>
                se pose des questions sur son orientation / sa réorientation :
              </strong>
            </p>
            <ul>
              <li>Quelle étude choisir ?</li>
              <li>Dans quel métier je me projette avec plaisir ?</li>
              <li>
                Mes cours ne m’intéressent pas, manque de motivation, que faire
                ?
              </li>
            </ul>`,
          },
        ],
      },
      {
        key: shortid.generate(),
        title: "Méthode pédagogique",
        items: [
          {
            key: shortid.generate(),
            subtitle: "",
            content: `  
            <p>Coach certifié ICF et en orientation scolaire</p>
            <p>Le coaching d’orientation est axé sur 3 grandes étapes :</p>
            <ul>
              <li>
                <strong>Connaissance de soi</strong> : aider le jeune à prendre du recul sur
                lui-même, à apprendre à mieux se connaître (talents, motivation,
                personnalité, vocation, environnement nécessaire, …) pour
                identifier ses critères de choix.
              </li>
              <li>
              <strong>Connaissance du monde du travail et des formations</strong> : aider le
                jeune à se faire une représentation réaliste et concrète des
                différentes études mais également des métiers qui y sont liés.
              </li>
              <li>
              <strong>Prise de choix et mise en place du projet</strong> : identification des
                étapes, des ressources disponibles et des blocages à éliminer
                pour réaliser le projet. Passer à l’action.
              </li>
            </ul>

            <p>
            Le coach utilise les techniques et outils du coaching et si nécessaire, des tests d’orientation (personnalité, vocation, …) 
            pour faciliter et débloquer les processus de réflexion.
            </p>
            <p>
              Le coach est expert dans le "questionnement" et l’étudiant est
              expert dans "la connaissance de soi".
            </p>
            <p>Accompagnement individuel par séance de 1H30. </p>`,
          },
        ],
      },
    ],
    informations: {
      title: "Informations pratiques",
      items: [
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Formules",
          content: `
          <p>
            Une séance de coaching d’orientation dure 1h30. Le nombre de séances
            nécessaires varie en fonction de l’avancement de la réflexion de
            l’étudiant (entre 2 et 5).
          </p>
          <p>Possibilité de passer également des tests.</p>
          <p>Bureau de consultation sur Ixelles et Etterbeek.</p>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Demande d’information et tarif ",
          content: `
          <p>Tarif : 90 euros/séance</p>
          <p>
            Les tests, étant fournis par un organisme externe (Halin Prémont
            institut) sont facturés à part. En fonction des tests cela coûte
            entre 35 et 100 euros.
          </p>
          <p>
            Contactez-nous au <a href="tel://+32477962457" title="Contactez-nous"><strong>+32 (0) 477 96 24 57</strong></a> ou via le
            <a href="#contact" class="services-form-link" title="Envoyez-nous un message"><strong>formulaire</strong></a>
          </p>`,
        },
        {
          key: shortid.generate(),
          col: 4,
          subtitle: "Remarques",
          content: `
          <p>
            Il arrive qu’un parcours d’orientation scolaire mette en lumière
            d’autres zones de développement personnel. Si le coach détecte un
            blocage plus profond (ex : manque d’affirmation de soi), il
            informera clairement l’étudiant et les parents et proposera un
            parcours de coaching spécifique pour travailler sur ce point (qui
            n’est plus une simple orientation scolaire).
          </p>`,
        },
      ],
    },
  },
}
