import React, { useState, useEffect, useRef, useCallback } from "react"
import get from "lodash/get"
import has from "lodash/has"

import services from "../locales/fr/services"
const useMessages = namespaces => {
  const lang = "fr"

  const messages = {}
  namespaces.forEach(namespace => {
    messages[namespace] = services
  })

  const getMessage = useCallback(
    key => {
      console.log("key", key)
      console.log("messages", messages)
      return get(messages, key, "not found")
    },
    [messages]
  )

  const format = useCallback((text, { html = false }) => {
    if (html) {
      return <div dangerouslySetInnerHTML={{ __html: text }} />
    }
    return text
  })

  return [getMessage, format]
}

export default useMessages
